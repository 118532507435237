import constants from '../utils/constants';

const loginSymbol = constants.__symbolPrefix + 'login'

const login = async (
    _,
    Promise,
    Device,
    ioc,
    schemeSettings,
    loginApi,
    lookup,
    loginHelpers,
    // setParameterByName,
    userInfo,
    registrationType
) => {
    let regData = {
        mode: 'regGM',
        duid: Device.deviceId,
        uid: Device.thrid,
        validate: true,
        schemeId: Device.schemeId,
        appName: Device.schemeId,
        app: Device.schemeId
    }

    regData = _.assign({}, regData, userInfo)

    if (registrationType === 'externalId') {
        // Object.keys(userInfo).forEach((key) => {
        //     setParameterByName(key, userInfo[key])
        // })
    }
    
    const loginLookup = lookup.makeLookup('login')
    regData = await loginLookup(regData)

    let hasEnteredPassword = ioc.injectWithModules(
        ['3r/login-api/helpers/requirePassword'],
        function(requirePassword) {
            if (requirePassword(schemeSettings)) {
                return regData.password
            } else {
                return true
            }
        }
    )

    // TODO: Confirm what value is suitable instead of test-referer
    let isRegistered = await loginApi.register(
        regData,
        '',
        loginHelpers.requirePassword(schemeSettings),
        hasEnteredPassword
    )

    if (!isRegistered) {
        throw new Error('Register error')
    }

    return isRegistered
}

login._depends = {
    symbol: loginSymbol,
    modules: [
        '_',
        'Promise',
        '3r/Device',
        constants.__symbolPrefix + 'ioc',
        '3r/schemeSettings/webapp',
        /^3r\/login-api\/login\//,
        /^3r\/login-api\/lookup\//,
        /^3r\/login-api\/helpers\//,
        // '3r/setParameterByName'
    ]
}

const loginService = {
    login,
    loginSymbol
}
export default loginService;
