import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Container from '@material-ui/core/Container'
import { UserContext } from "../UserContext";
import ModuleInjection from "../moduleInjections";
import getSchemeSettingsHelper from "../services/getSchemeSettings"
import defaultErrorImage from '../images/error.png';

const defaultStyles = {
    containerStyle: {
        padding: "5rem 2rem 1rem 2rem",
        textAlign: "center"
    },
    imageContainerStyle: {
        height: "10rem"
    },
    titleStyle: {
        marginBottom: "1rem",
        lineHeight: "1.5rem",
        fontSize: "1.2rem",
        fontWeight: "bold"
    },
    msgStyle: {
        marginBottom: "1rem",
        lineHeight: "1.5rem",
        fontSize: "1rem"
    },
    imageStyle: {
        maxWidth: "100%",
        maxHeight: "100%"
    }
}

class ErrorPage extends Component {
    static contextType = UserContext
    constructor(props){
        super(props);
        this.state = {
            data: null,
            settings: null
        };
    }

    async componentDidMount(){
        this.stateData = this.props.location.state || {}
        let ioc = ModuleInjection.getIoc();
        let settings
        if(ioc && ioc.isDefined(getSchemeSettingsHelper.getCachedSchemeSettingsSymbol)){
            settings = ioc.run(getSchemeSettingsHelper.getCachedSchemeSettingsSymbol)
        }
        
        settings = (((settings || {}).globals || {}).sta || {}).error || {}
        settings.styles = settings.styles || defaultStyles
        console.log("settings=", settings)
        this.setState({
            data: this.stateData.redirectData,
            settings: settings
        })
    }

    renderTitle(data, styles){
        return data.title ? <div style={styles.titleStyle}>{data.title}</div> : null
    }

    renderErrorCode(data, styles){
        return data.errorCode ? <div style={styles.msgStyle}>{"Error code: " + data.errorCode}</div> : null
    }

    render() {
        console.log("this.state.data=", this.state.data)
        let data = this.state.data
        let settings = this.state.settings
        if(data && settings){
            let styles = settings.styles || {}
            return (
                <Container maxWidth="sm">
                    <div style={styles.containerStyle}>
                        <div style={styles.imageContainerStyle}>
                            <img src={(settings.images || {}).errorImage || defaultErrorImage} style={styles.imageStyle} alt=""></img>
                        </div>
                        {this.renderTitle(data, styles)}
                        {/* {this.renderErrorCode(data, styles)} */}
                        <div style={styles.msgStyle}>{data.errorMsg || ""}</div>
                    </div>
                </Container>
            )
        }else{
            return null
        }
    }
}

export default withRouter(ErrorPage);
