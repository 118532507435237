import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Container from '@material-ui/core/Container'
import { UserContext } from "../UserContext";
import CheckIcon from '@material-ui/icons/Check';

let containerStyle = {
    padding: "1rem 0",
    textAlign: "center"
}
let titleStyle = {
    fontSize: "1rem",
    lineHeight: "3rem",
    fontWeight: "bold",
    textAlign: "left",
    color: "#6F7981"
}
let rowStyle = {
    position: "relative",
    marginTop: "1rem",
    padding: "1rem 0",
    border: "1px solid white",
    boxShadow: "0 0 6px 1px rgb(236, 233, 233)",
    backgroundColor: "white"
}
let prizeDescStyle = {
    padding: "0 2rem",
    fontSize: "1rem",
    lineHeight: "1.5rem"
}
let selectTickStyle = {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: "0.1rem",
    margin: "auto 0",
    width: "1.6rem",
    height: "1.6rem"
}
let bottomBarStyle = {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "0 5%",
    width: "90%",
    height: "4rem",
    textAlign: "center"
}
let disabledBtnStyle = {
    height: "3rem",
    lineHeight: "3rem",
    fontSize: "1rem",
    borderRadius: "0.3rem",
    color: "white",
    borderRadius: "0.5rem",
    backgroundColor: "rgb(236, 233, 233)"
}
let bottomBtnStyle = {
    height: "3rem",
    lineHeight: "3rem",
    fontSize: "1rem",
    borderRadius: "0.3rem",
    color: "white",
    borderRadius: "0.5rem",
    backgroundColor: "#ff3d40"
}

class MultipleWin extends Component {
    static contextType = UserContext
    constructor(props){
        super(props);
        console.log("MultipleWin - props=", props)
        this.state = {
            user: undefined
        };
    }

    async componentDidMount(){
        
    }

    toggoleSelectPrize(prize){
        let selectedPrize = this.state.selectedPrize
        if(selectedPrize && selectedPrize.description == prize.description){
            this.setState({
                selectedPrize: null
            })
        }else{
            this.setState({
                selectedPrize: prize
            })
        }
    }

    confirmSelectPrize(){
        let prize = this.state.selectedPrize
        this.props.history.push({
            pathname: '/WinPrizeDetail',
            search: '',
            state: {
                redirectData: {
                    gameChance: this.props.location.state.redirectData.gameChance,
                    currentMessage: this.props.location.state.redirectData.currentMessage,
                    winMessageTemplate: this.props.location.state.redirectData.winMessageTemplate,
                    prize: prize
                },
                ioc: this.props.location.state.ioc,
                isMultipleWin: true,
                selectedPrize: null
            }
        })
    }

    renderPrizeOptions(){
        let self = this
        let selectedPrize = self.state.selectedPrize
        let prizeOptions = this.props.location.state.redirectData.prizeOptions || []
        let allocation = this.props.location.state.redirectData.allocation || {}
        let unlockCodeCounts = allocation.unlockCodeCounts
        let validPrizes = []
        
        prizeOptions.forEach(p => {
            unlockCodeCounts.forEach(u => {
                if(p.unlock == u.code  && u.count > 0){
                    validPrizes.push(p)
                }
            })
        })

        /**
         * This should not happen because allocate should return error instead of prize info
         * it's only for double check
         */
        if(validPrizes.length == 0){
            this.props.history.push({
                pathname: '/ErrorPage',
                search: '',
                state: {
                    redirectData: {
                        title: "Sorry, this promotion has been fully redeemed.",
                        errorMsg: ""
                    }
                }
            })
        }
        
        return validPrizes.map((p, index) => {
            if(selectedPrize && selectedPrize.description == p.description){
                return(
                    <div key={"row" + index} style={rowStyle} onClick={() => {self.toggoleSelectPrize(p)}}>
                        <div style={prizeDescStyle}>{p.description}</div>
                        <CheckIcon style={selectTickStyle}></CheckIcon>
                    </div>
                )
            }else{
                return(
                    <div key={"row" + index} style={rowStyle} onClick={() => {self.toggoleSelectPrize(p)}}>
                        <div style={prizeDescStyle}>{p.description}</div>
                    </div>
                )
            }
            
        })
    }

    renderBottomBar(){
        let buttonText = "CONFIRM"
        if(this.state.selectedPrize){
            return (
                <div style={bottomBarStyle} onClick={() => {this.confirmSelectPrize()}}>
                    <div style={bottomBtnStyle}>{buttonText}</div>
                </div>
            )
        }else{
            return (
                <div style={bottomBarStyle}>
                    <div style={disabledBtnStyle}>{buttonText}</div>
                </div>
            )
        }
    }

    render() {
        let title = "SELECT ONE GIFT"

        return (
            <Container maxWidth="sm">
                <div style={containerStyle}>
                    <div style={titleStyle}>{title}</div>
                    {this.renderPrizeOptions()}
                </div>
                {this.renderBottomBar()}
            </Container>
        )
    }
}

export default withRouter(MultipleWin);
