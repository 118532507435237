import constants from '../utils/constants';

const getExternalId = (schemeSettings, getParameterByName) => {
    return (((schemeSettings || {}).globals || {}).externalIdParameters || "externalId").split(",").reduce(function (externalId, param) {
        return getParameterByName(param.trim()) || externalId
    }, null)
}

const getExternalIdSymbol = constants.__symbolPrefix + 'getExternalId';
getExternalId._depends = {
    symbol: getExternalIdSymbol,
    modules: ["3r/schemeSettings/webapp", "3r/getParameterByName"]
}

const getExternalIdHelper = {
    getExternalIdSymbol, 
    getExternalId
}
export default getExternalIdHelper;
