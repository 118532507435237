import chiHelpers from 'chi-helpers/helpers'
import constants from '../utils/constants';

const resolveThreeRadicalSymbol = constants.__symbolPrefix + 'resolveThreeRadical'

const resolveThreeRadical = chiHelpers.resolveThreeRadical
resolveThreeRadical._depends = {
    symbol: resolveThreeRadicalSymbol,
    modules: []
}

const threeRadicalUtils = {
    resolveThreeRadicalSymbol,
    resolveThreeRadical
}
export default threeRadicalUtils;
