import config from "../config"

const __symbolPrefix = '3r/single-tactic-app/'

const messageProcessorSymbolPrefix = '3r/messageProcessorFactories/'

const defaultDelayInMilliSeconds = 1000

const userTrafficDelayInMilliSeconds =
    config.userTrafficDelayInMilliSeconds || defaultDelayInMilliSeconds
const userButtonPressDelayInMilliSeconds =
    config.userButtonPressDelayInMilliSeconds || defaultDelayInMilliSeconds
const userRandomUnlockDelayInMilliSeconds =
    config.userRandomUnlockDelayInMilliSeconds || defaultDelayInMilliSeconds

const Constants = {
    __symbolPrefix,
    messageProcessorSymbolPrefix: messageProcessorSymbolPrefix,
    userTrafficDelayInMilliSeconds: userTrafficDelayInMilliSeconds,
    userButtonPressDelayInMilliSeconds: userButtonPressDelayInMilliSeconds,
    userRandomUnlockDelayInMilliSeconds: userRandomUnlockDelayInMilliSeconds,
    logConfig: {
        noInfoLog: config.noInfoLog,
        noWarningLog: config.noWarningLog,
        noErrorLog: config.noErrorLog,
        showLogTime: config.showLogTime
    },
    maxMessagesPerUser: config.maxMessagesPerUser,
    maxButttonsPerMessage: config.maxButttonsPerMessage,
    maxRandomUnlocksPerUser: config.maxRandomUnlocksPerUser,
    userBatchConfig: config.userBatch || {}
}
export default Constants;
