import configLocal from "./config.local.json"
import configDemo from "./config.demo.json"
import configPro from "./config.pro.json"

let config = null
let environment = process.env['NODE_ENV'] || "" 

if (environment === "development") {
    config = configLocal
} else if (window.location.hostname.startsWith("demo-sta")) {
    config = configDemo
} else {
    config = configPro
}

export default config

