import constants from '../utils/constants';
import { default as gameThrowsAlias } from 'chi-helpers/game-throws';
import gameDeviceHelper from './game-device';

const generateThrowsSymbol = constants.__symbolPrefix + 'generateThrows'
const updatePlayerDataSymbol = constants.__symbolPrefix + 'updatePlayerData'

const generateThrows = gameThrowsAlias.generateThrows

generateThrows._depends = {
    symbol: generateThrowsSymbol,
    modules: [
        gameDeviceHelper.GameDeviceSymbol,
        updatePlayerDataSymbol
    ]
}

const updatePlayerData = gameThrowsAlias.updatePlayerData

updatePlayerData._depends = {
    symbol: updatePlayerDataSymbol,
    modules: [
        "3r/Device"
    ]
}

let gameThrows = {
    generateThrowsSymbol,
    generateThrows,
    updatePlayerDataSymbol,
    updatePlayerData
}
export default gameThrows;