import constants from '../utils/constants';
import camelCase from '../utils/camel-case';
import GameNavigation from '../lib/game-navigation';
import { history } from '../utils/history';

const getPackageJsAddressSymbol = constants.__symbolPrefix + 'getPackageJsAddress'
const createMainScriptSymbol = constants.__symbolPrefix + 'createMainScript'
const getPackageNameSymbol = constants.__symbolPrefix + 'getPackageName'
const getPackageGuidSymbol = constants.__symbolPrefix + 'getPackageGuid'
const initPackageObjectSymbol = constants.__symbolPrefix + 'initPackageObject'
const GameDeviceSymbol = constants.__symbolPrefix + 'GameDevice'
const gameRandomSymbol = constants.__symbolPrefix + 'gameRandom'
const generateThrowsSymbol = constants.__symbolPrefix + 'generateThrows'
const resolveThreeRadicalSubPackageSymbol = constants.__symbolPrefix + 'resolveThreeRadicalSubPackage'

let isRemapFilePaths = false
if (typeof window == "object" && (/app\.vocohub\.com/.test(window.location.href) || /cdn\.vocohub\.com/.test(window.location.href)) ) {
    isRemapFilePaths = true
}

const getPackageJsAddress = async (
    _,
    Device,
    schemeSettings,
    getParameterByName,
    messageService,
    getPackageName,
    createMainScript,
    initPackageObject,
    tacticId,
    isReplayGame
) => {
    // let launchParam = schemeSettings.globals.launchParameter || "displayTactic"
    // let tacticId = getParameterByName(launchParam)  //must be acceptable by Device.getMessage like systemId
    let packageParam = schemeSettings.globals.packageParam || "package"
    let forcePackageId = getParameterByName(packageParam)

    if(!tacticId) {
		return { isLoaded: false, error: "No message ID provided" }
    }

    let messages = Device.info.messages.filter(m => m.systemId == tacticId)
    if(messages.length === 0){
        return { isLoaded: false, error: "No message found with ID " + tacticId }
    }
    let message = messages[0]
	messageService.read(message)

    let packageName
    packageName = await getPackageName(message, forcePackageId)

    if(isReplayGame){
        await initPackageObject(packageName, message, Device.schemeId, forcePackageId)
    } else {
        if(!window[packageName] || forcePackageId) {
            await createMainScript(message, forcePackageId)
        }

        await initPackageObject(packageName, message, Device.schemeId, forcePackageId)
    }

    return { isLoaded: true }
}

getPackageJsAddress._depends = {
    symbol: getPackageJsAddressSymbol,
    modules: [
        '_',
        '3r/Device',
        '3r/schemeSettings/webapp',
        '3r/getParameterByName',
        '3r/messageService',
        getPackageNameSymbol,
        createMainScriptSymbol,
        initPackageObjectSymbol
    ]
}

const createMainScript = async (
    _, 
    Device, 
    getPackageGuid,
    message, 
    forcePackageId
) => {
    return new Promise(function(resolve, reject){
        let packageGuid = getPackageGuid(message, forcePackageId)
        
        window.packageQueue = window.packageQueue || []

        if(window.packageQueue.filter(function(item) {
            return item === packageGuid
        }).length > 0) {
            reject('package already in queue')
        } else {
            window.packageQueue.push(packageGuid)
            var mainJsAddress = Device.getApiAddress({
                port: 3001,
                path: "/external-package/" + message.schemeId + "/" + packageGuid + "/main.js"
            })

            var script = document.createElement('script')
            script.onload = function() {
                resolve(mainJsAddress)
            }
            
            script.src = mainJsAddress
            document.getElementsByTagName('body')[0].appendChild(script)
        }
    })
}
createMainScript._depends = {
    symbol: createMainScriptSymbol,
    modules: [
        "_",
        "3r/Device",
        getPackageGuidSymbol
    ]
}

const getPackageName = async (_, Device, message, forcePackageId) => {
    if(forcePackageId){
        return await Device.gtp("/external-package/" + Device.schemeId + "/" + forcePackageId + "/package/name")
    } 
    
    let npmPackage = _.get(message, "quantities.link.data.externalPackage.threeRadical._package", null)
    if(npmPackage && npmPackage.name) {
        return camelCase(npmPackage.name)
    } else {
        throw new Error("No package parameter defined & Message is missing package configuration")
    }
}
getPackageName._depends = {
    symbol: getPackageNameSymbol,
    modules: [
        "_",
        "3r/Device"
    ]
}

const getPackageGuid = (_, schemeSettings, getPackageName, message, forcePackageId) => {
    if(forcePackageId){
        return forcePackageId
    }
    
    let metadataPath
    const uploadOption = _.get(message, 'quantities.link.data.externalPackage.uploadOption', '')
    
    if (uploadOption === 'selectPackage') {
        const packageName = _.get(message, "quantities.link.data.externalPackage.threeRadical._package.name", null)
        const schemePackages = _.get(schemeSettings, 'globals.schemePackages', [])
        const correspondingSchemePackage = schemePackages.find(
            (packageItem) => packageItem && packageItem.name && packageItem.name === packageName
        )
        console.log("00 mark, correspondingSchemePackage=", correspondingSchemePackage)
        const correspondingSchemePackagePath = correspondingSchemePackage && correspondingSchemePackage.file
        
        if (correspondingSchemePackagePath) {
            const metadataPathIndex = correspondingSchemePackagePath.indexOf('/_/gtp/')
            metadataPath = correspondingSchemePackagePath.slice(metadataPathIndex)
        }
    }

    if (!metadataPath) {
        metadataPath = message.quantities.link.data.externalPackage.metadataPath
    }

    var argParts = metadataPath.split('/')
    return argParts[argParts.length - 1].replace('.zip', '')
}
getPackageGuid._depends = {
    symbol: getPackageGuidSymbol,
    modules: [
        "_",
        '3r/schemeSettings/webapp',
        getPackageNameSymbol
    ]
}

// const updatePlayerData = (Device, data) => {
//     return Device.data(data).then(function() {
//         return Device.getInfo();
//     })
// }
// updatePlayerData._depends = {
//     symbol: updatePlayerDataSymbol,
//     modules: [
//         "3r/Device"
//     ]
// }

const initPackageObject = async (
    Device, 
    GameDevice,
    gameRandom,
    generateThrows,
    threeRadicalUtils,
    showSnackBarMsg,
    _,
    ioc,
    getCachedSchemeSettings,
    getParameterByName,
    resolveThreeRadicalSubPackage,
    schemeSettings,
    pkgName, 
    message, 
    schemeId,
    packageId
) => {
    let packageName = camelCase(pkgName)
    var gameNavigation = new GameNavigation(_, ioc, getCachedSchemeSettings, getParameterByName, history)
    var helperFunctions = {
        camelCase: camelCase
    }
    var gameDevice = new GameDevice(message, gameNavigation, Device, helperFunctions, schemeId)
    var gameRandom = gameRandom(schemeId, message)
    var throws = generateThrows(message.systemId)
    var targetElement = document.querySelector("div[namespace='webframework-external-package'] .webframework-external-package-wrapper")
    targetElement.innerHTML = ''
    let moduleSchemeSettings  = {}
    
    // console.log("todo: support message.quantities.link.data.externalPackage.threeRadical.allowScroll")
    // if(message.quantities.link.data.externalPackage.threeRadical.allowScroll) {
    //     externalPackageUtilityService.addClass(targetElement, 'can-scroll')
    // }

    let packageConfig
    if(packageId){
        /**
         * RND-5: force tactic to load the specilfied package's default config (the "threeRadical" from game's package.json)
         * But the default config must have default value for the compulsory fields
         */
        packageConfig = await Device.gtp("external-package/" + schemeId + "/" + packageId + "/default")
    }else{
        packageConfig = message.quantities.link.data.externalPackage.threeRadical
    }
    
    let config = threeRadicalUtils.resolveThreeRadical(packageConfig, 'threeRadical', true, resolveThreeRadicalSubPackage, {"isRemapFilePaths": isRemapFilePaths})

    let locale = ioc.run("3r/chi/factories/locale", message)
    let initSchemeSettings  = {}
    
    if (packageName && schemeSettings.globals && schemeSettings.globals[packageName]) {
        initSchemeSettings[packageName] = schemeSettings.globals[packageName];
    }
    
    // config.canvas = targetElement
    window[packageName].default(
        {
            throws : throws,
            device : gameDevice,
            navigation: gameNavigation,
            random : gameRandom, 
            realDevice : Device,
            ioc : ioc,
            locale: locale,
            schemeSettings : initSchemeSettings,
            isStaModule: function(){
                return true
            },
            message : function (message) {
                // console.log("modules.message - message=", message, ", options=", options)
                // if(options && (options.redirect == "WinPrizeDetail" || options.redirect == "MultipleWin")){
                //     return updateParam("redirectPage", options)
                // }else{
                    return showSnackBarMsg(message)
                // }
            }
            // webframeworkAngular: {
            //     addFactories: function(factories) {
            //         factories.forEach(function(factoryObj) {
            //             webframeworkAngularProviders.$provide.factory(factoryObj.name, factoryObj.factory);
            //         });
            //     },
            //     addStates: function(stateObjects) {
            //         stateObjects.forEach(function(stateObject) {
            //             webframeworkAngularProviders.$stateProvider.state(stateObject.name, stateObject.stateDefinition);
            //         });
            //     },
            //     addControllers: function(controllers) {
            //         controllers.forEach(function(controllerObject) {
            //             webframeworkAngularProviders.$controllerProvider.register(
            //                 controllerObject.name,
            //                 controllerObject.controller
            //             );
            //         });
            //     },
            //     injectDynamicHtml: function(containerElement, htmlString) {
            //         const wrappedDynamicContainer = angular.element(containerElement);
            //         wrappedDynamicContainer.html(htmlString);
            
            //         angular
            //             .element(targetElement)
            //             .injector()
            //             .invoke([
            //                 '$compile',
            //                 '$rootScope',
            //                 function($compile, $rootScope) {
            //                     $compile(wrappedDynamicContainer)($rootScope);
            //                 }
            //             ]);
            //     },
            //     launchState: function(stateName, stateParams, options) {
            //         $state.go(stateName, stateParams || {}, options || {});
            //     },
            //     providers: webframeworkAngularProviders,
            //     stateData: {
            //         parentRootState: 'app.',
            //         parentHomeState: 'app.home.',
            //         webframeworkState: 'app.webframeworkexternalpackage.'
            //     }
            // }
        },
        config,
        targetElement,
        message
    )
}
initPackageObject._depends = {
    symbol: initPackageObjectSymbol,
    modules: [
        "3r/Device",
        GameDeviceSymbol,
        gameRandomSymbol,
        generateThrowsSymbol,
        "3r/threeRadicalUtils",
        constants.__symbolPrefix + 'showSnackBarMsg',
        "_",
        constants.__symbolPrefix + 'ioc',
        constants.__symbolPrefix + 'getCachedSchemeSettings',
        '3r/getParameterByName',
        '3r/chi/resolveThreeRadicalSubPackage',
        "3r/schemeSettings/webapp"
    ]
}


const externalPackageUtilityService = {
    getPackageJsAddressSymbol,
    getPackageJsAddress,
    createMainScriptSymbol,
    createMainScript,
    getPackageNameSymbol,
    getPackageName,
    getPackageGuidSymbol,
    getPackageGuid,
    initPackageObjectSymbol,
    initPackageObject,
    resolveThreeRadicalSubPackageSymbol
}
export default externalPackageUtilityService;
