import constants from '../utils/constants';
import { default as  gameDeviceHelperAlias } from 'chi-helpers/game-device'

const GameDeviceSymbol = constants.__symbolPrefix + 'GameDevice'

const GameDevice = gameDeviceHelperAlias.GameDevice

GameDevice._depends = {
    symbol: GameDeviceSymbol,
    modules: []
}

const gameDeviceHelper = {
    GameDeviceSymbol,
    GameDevice
}

export default gameDeviceHelper;
