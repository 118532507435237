export default function camelCase(input, options) {
    if (Array.isArray(input)) {
        input = input.map(x => x.trim())
            .filter(x => x.length)
            .join('-');
    } else {
        input = input.trim()
    }

    if (input.length === 0) {
        return ''
    }

    if (input.length === 1) {
        return (options || {}).pascalCase ? input.toUpperCase() : input.toLowerCase()
    }

    // if (/^[a-z\d]+$/.test(input)) {
    //     return postProcess(input)
    // }

    const hasUpperCase = input !== input.toLowerCase()

    if (hasUpperCase) {
        input = preserveCamelCase(input)
    }

    input = input
        .replace(/^[_.\- ]+/, '')
        .toLowerCase()
        .replace(/[_.\- ]+(\w|$)/g, (m, p1) => p1.toUpperCase())
    return input
}

function preserveCamelCase(input) {
    var isLastCharLower = false
    var isLastCharUpper = false
    var isLastLastCharUpper = false

    for (let i = 0; i < input.length; i++) {
        var c = input[i]

        if (isLastCharLower && /[a-zA-Z]/.test(c) && c.toUpperCase() === c) {
            input = input.slice(0, i) + '-' + input.slice(i)
            isLastCharLower = false
            isLastLastCharUpper = isLastCharUpper
            isLastCharUpper = true
            i++
        } else if (isLastCharUpper && isLastLastCharUpper && /[a-zA-Z]/.test(c) && c.toLowerCase() === c) {
            input = input.slice(0, i - 1) + '-' + input.slice(i - 1)
            isLastLastCharUpper = isLastCharUpper
            isLastCharUpper = false
            isLastCharLower = true
        } else {
            isLastCharLower = c.toLowerCase() === c
            isLastLastCharUpper = isLastCharUpper
            isLastCharUpper = c.toUpperCase() === c
        }
    }

    return input;
  }
