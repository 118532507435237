import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Container from '@material-ui/core/Container'
import { UserContext } from "../UserContext";
import loginService from "../services/loginService";
import ModuleInjection from "../moduleInjections";
import getSchemeSettingsHelper from "../services/getSchemeSettings"
import loadingService from "../services/loading"

import config from "../config"

class Login extends Component {
    static contextType = UserContext
    constructor(props){
        super(props);
        this.state = {
            user: undefined,
            settings: null
        };
    }

    async componentDidMount(){
        let serverUrl = config.serverUrl
        // let schemeId = config.schemeId
        let ioc = await ModuleInjection.getNewInjectionInstance(serverUrl)

        if(!this.context.user){
            if(!ioc) return
            
            try{
                let schemeId = ioc.run("3r/getParameterByName", "schemeId")
                let settings = await ioc.run(getSchemeSettingsHelper.getSchemeSettingsSymbol, schemeId)
                if(settings.error){
                    return this.goErrorPage({
                        title: "Service not available",
                        errorMsg: "This service is currently unavailable, please try again later."
                    })
                }

                this.setState({
                    settings: settings
                })
                ioc.run(getSchemeSettingsHelper.setCachedSchemeSettingsSymbol, settings)

                let userInfo = {}
                await ioc.run(loginService.loginSymbol, userInfo, "externalId")
                this.props.history.push({
                    pathname: '/LoadChiTactic',
                    search: '',
                    state: { ioc: ioc }
                })
            }catch(error){
                console.error("Login - error: ", error)
                this.goErrorPage({
                    title: "Service not available",
                    errorMsg: "This service is currently unavailable, please try again later."
                })
            }
        }else{
            console.log("User already login")
        }
    }

    goErrorPage = (error) => {
        this.props.history.push({
            pathname: '/ErrorPage',
            search: '',
            state: {
                redirectData: error
            }
        })
    }

    render(){
        let settings = this.state.settings
        let loadingStyles = loadingService.initLoading(settings)
        let msg = "Logging In..."

        return (
            <Container maxWidth="sm" disableGutters={true} style={loadingStyles.containerStyle}>
                {loadingService.getLoadingText(msg)}
                {loadingService.renderImage()}
            </Container>
        )
    }
}

export default withRouter(Login);
