import constants from '../utils/constants';

const getSchemeSettingsSymbol = constants.__symbolPrefix + 'getSchemeSettings'
const getCachedSchemeSettingsSymbol = constants.__symbolPrefix + 'getCachedSchemeSettings'
const setCachedSchemeSettingsSymbol = constants.__symbolPrefix + 'setCachedSchemeSettings'
let schemeSettings

const getSchemeSettings = async (http, getApiAddress, schemeId) => {
    return new Promise(function(resolve, reject){
        const apiPort = 3040
        http({ 
            url: getApiAddress({ 
                port: apiPort, 
                path: `settings/${schemeId}/webapp?noLoadingImage=true` 
            }) 
        }).then(function(result){
            resolve(result)
        }, function(e){
            resolve({error: "fail to get getSchemeSettings"})
        })
    })
}
getSchemeSettings._depends = {
    symbol: getSchemeSettingsSymbol,
    modules: ['3r/device-api/device/http', '3r/getApiAddress']
}

const getCachedSchemeSettings = () => {
    return schemeSettings
}
getCachedSchemeSettings._depends = {
    symbol: getCachedSchemeSettingsSymbol,
    modules: []
}

const setCachedSchemeSettings = (settings) => {
    schemeSettings = settings
}
setCachedSchemeSettings._depends = {
    symbol: setCachedSchemeSettingsSymbol,
    modules: []
}


const getSchemeSettingsHelper = {
    getSchemeSettingsSymbol, 
    getSchemeSettings,
    getCachedSchemeSettingsSymbol,
    getCachedSchemeSettings,
    setCachedSchemeSettingsSymbol,
    setCachedSchemeSettings
}
export default getSchemeSettingsHelper;
