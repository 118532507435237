import React, { Component } from 'react';
import { UserContext } from './UserContext';
import './App.css';
import Index from './routes/Index';
import Login from './routes/Login';
import LoadChiTactic from './routes/LoadChiTactic';
import WinPrizeDetail from './routes/WinPrizeDetail';
import MultipleWin from './routes/MultipleWin';
import ErrorPage from './routes/ErrorPage'
import {
    Redirect,
    Router,
    Switch,
    Route
} from "react-router-dom"
import { history } from './utils/history';

class App extends Component{
    static contextType = UserContext
    constructor(props){
        super(props)
        this.state = {
            redirect: null
        }
    }
    
    async componentDidMount(){

    }

    render(){
        let toPath
        if (!this.context.user) {
            toPath = "/Login"
        }else{
            toPath = this.state.redirect || ""
        }

        return (
            <UserContext.Provider value={{user: "", ioc: ""}}>
                <Router history={history}>
                    <Switch>
                        if((!this.context.user || this.state.redirect)){
                            <Redirect exact from="/" to={toPath}/>
                        }
                        <Route path = "/Login">
                            <Login />
                        </Route>
                        <Route path = "/LoadChiTactic">
                            <LoadChiTactic />
                        </Route>
                        <Route path = "/WinPrizeDetail">
                            <WinPrizeDetail />
                        </Route>
                        <Route path = "/MultipleWin">
                            <MultipleWin />
                        </Route>
                        <Route path="/ErrorPage">
                            <ErrorPage />
                        </Route>
                        <Route exact path="/">
                            <Index />
                        </Route>
                    </Switch>
                </Router>
            </UserContext.Provider>
        )
    }
}

export default App;
