import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { Container, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { UserContext } from "../UserContext";
import externalPackageUtilityService from "../services/externalPackageUtilityService"
import constants from '../utils/constants'
import loadingService from "../services/loading"

import getSchemeSettingsHelper from "../services/getSchemeSettings"

let externalPackageStyle = {
    textAlign: "center"
}
let externalPackageWrapperStyle = {
    height: "100%"
}
let snackBarStyles = {
    height: "100%"
}
let imgWrapperStyle = {
    width: "100%"
}

class LoadChiTactic extends Component {
    // _isMounted = false;
    static contextType = UserContext

    constructor(props){
        super(props);
        this.state = {
            snackBarOptions: {
                title: "",
                message: "",
                image: "",
                severity: "",
                open: false
            },
            gameLoaded: false
        }
        this.logout = this.logout.bind(this)
        this.resetTimeout = this.resetTimeout.bind(this)

        this.ioc = null
    }

    async componentDidMount(){
        // this._isMounted = true
        window.addEventListener('game.loaded', this.setChiGameLoaded)
        
        if(this.props.location.state && this.props.location.state.ioc){
            this.ioc = this.props.location.state.ioc
            let isReplayGame = this.props.location.state.isReplayGame

            if(!this.ioc.isDefined(constants.__symbolPrefix + 'showSnackBarMsg')){
                const showSnackBarMsg = (msgDetails) => {
                    this.setState({
                        snackBarOptions: {
                            title: msgDetails.title,
                            message: msgDetails.message,
                            image: msgDetails.image,
                            severity: msgDetails.severity || "success",
                            open: true
                        }
                    })
                }
                showSnackBarMsg._depends = {
                    symbol: constants.__symbolPrefix + 'showSnackBarMsg',
                    modules: []
                }
                this.ioc.inject(showSnackBarMsg)
            }

            // if(!this.ioc.isDefined(constants.__symbolPrefix + 'setChiGameLoaded')){
            //     const setChiGameLoaded = (gameLoaded) => {
            //         if (!this.state.gameLoaded && gameLoaded) {
            //             this.setState({
            //                 gameLoaded: true
            //             })
            //         }
            //     }
            //     setChiGameLoaded._depends = {
            //         symbol: constants.__symbolPrefix + 'setChiGameLoaded',
            //         modules: []
            //     }
            //     this.ioc.inject(setChiGameLoaded)
            // }

            let tacticId = null

            if (this.ioc.isDefined("3r/getTacticToLaunch")) {
                tacticId = await Promise.resolve(this.ioc.run("3r/getTacticToLaunch"))
            } else {
                tacticId = this.ioc.run("3r/getParameterByName", "displayTactic")
            }

            if(!tacticId){
                console.log("Unable to resolve tactic ID.")
                this.goErrorPage({
                    title: "Service not available",
                    errorMsg: "This service is currently unavailable, please try again later."
                })
            }

            if (this.ioc.isDefined("3r/chi/validateTacticLaunch")) {
                let errors = (await Promise.resolve(this.ioc.run("3r/chi/validateTacticLaunch", { isReplayGame : isReplayGame }, tacticId))) || []

                if (errors.length) {
                    let firstError = errors[0]

                    if (typeof firstError == "object") {
                        return this.goErrorPage(firstError)
                    } else {
                        return this.goErrorPage({ errorMsg : firstError.toString() })
                    }
                }
            }

            let loadResult = await this.ioc.run(externalPackageUtilityService.getPackageJsAddressSymbol, tacticId, isReplayGame)

            if (loadResult && !loadResult.isLoaded) {
                return this.goErrorPage({errorMsg: loadResult.error || "ExP Load Error"})
            }

            try {
                // let serverUrl = config.serverUrl
                let Device = await Promise.resolve(this.ioc.run("3r/Device"))
                this.settings = await this.ioc.run(getSchemeSettingsHelper.getSchemeSettingsSymbol, Device.schemeId)
                if (this.settings.globals.inactivitySettings) {
                    this.events = [
                        "load",
                        "mousemove",
                        "mousedown",
                        "click",
                        "scroll",
                        "keypress"
                    ]
    
                    for (var i in this.events) {
                        window.addEventListener(this.events[i], this.resetTimeout)
                    }
                    this.state.inactivityTimeout = this.settings.globals.inactivitySettings.inactivityTimeoutInSeconds
                    this.setTimeout(this.state.inactivityTimeout)                
                }
                let launchIntro = function (message) {
                    Device.trigger('tactic.modal', message)
                }
                let helpMessage = Device.info.messages.filter(m => m.systemId == tacticId)[0]
                launchIntro(helpMessage)
                
            } catch (e) {
                console.log('Error : ', e)
            }

        }else{
            this.props.history.push("/")
        }
    }

    componentWillUnmount() {
        window.removeEventListener('game.loaded', this.setChiGameLoaded)
    }

    setChiGameLoaded = () => {
        console.log("00 mark: game.loaded")
        if (this.state && !this.state.gameLoaded) {
            this.setState({
                gameLoaded: true
            })
        }
    }

    goErrorPage(data){
        this.props.history.push({
            pathname: '/ErrorPage',
            search: '',
            state: {
                redirectData: data
            }
        })
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({
            snackBarOptions: {
                title: "",
                message: "",
                image: "",
                severity: "success",
                open: false
            }
        })
    };

    clearTimeout() {
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout)
    }
    
    setTimeout(timeOutSec) {        
        let timeOutMilliSec = timeOutSec * 1000
        this.logoutTimeout = setTimeout(this.logout, timeOutMilliSec)
    }
    
    resetTimeout() {
        this.clearTimeout()
        this.setTimeout(this.state.inactivityTimeout)
    }
    
    
    logout() {
        this.destroy()
        if (this.settings.globals.inactivitySettings.inactivityRedirectUrl) {
            if(this.settings.globals.inactivitySettings.showTimeOutAlert){
                alert(this.settings.globals.inactivitySettings.sessionTimeoutAlert || "Your session has timed out,  please re-login.")
            }
            
            if (this.settings.globals.inactivitySettings.inactivityRedirectUrl) {
                if(this.settings.globals.inactivitySettings.redirectFromIframeParent){
                    window.top.location = this.settings.globals.inactivitySettings.inactivityRedirectUrl
                } else {
                    window.location = this.settings.globals.inactivitySettings.inactivityRedirectUrl
                }
            }
        }
    }
    
    destroy() {
        this.clearTimeout()
        for (var i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout)
        }
    }

    render() {
        let options = this.state.snackBarOptions 
        console.log("LoadChiTactic - render() - options=", options)
        let title = options.title ? options.title + "\n" : ""
        let message = options.message
        let severity = options.severity
        let open = options.open
        let image = options.image
        let snackBarImgStyles = {
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
            display: image ? "block" : "none"
        }
        let vertical = "top"
        let horizontal = "center"

        let loadingStyles = {}
        let packageStyle
        let showLoading = loadingService.showLoadingBeforeGameLoaded && loadingService.showLoadingBeforeGameLoaded() && !this.state.gameLoaded
        if(showLoading){
            loadingStyles = loadingService.getStyles()
            packageStyle = loadingService.getHideStyle()
        }else{
            loadingStyles = {}
            packageStyle = externalPackageStyle
        }
        let msg = "Loading Game..."

        return (
            <Container maxWidth={false} disableGutters={true} style={loadingStyles.containerStyle}>
                {showLoading ? loadingService.getLoadingText(msg) : null}
                {showLoading ? loadingService.renderImage() : null}
                
                <Snackbar 
                    open={open} 
                    autoHideDuration={5000} 
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical, horizontal }}
                    style={snackBarStyles}
                >
                    <Alert onClose={this.handleClose} severity={severity || "success"}>
                        {title + message}
                        <div style={imgWrapperStyle}>
                            <img src={image} style={snackBarImgStyles} alt=""></img>
                        </div>
                    </Alert>
                </Snackbar>

                <div namespace='webframework-external-package' style={packageStyle}>
                    <div className="webframework-external-package-wrapper" style={externalPackageWrapperStyle}></div>
                </div>
            </Container>
        )
    }
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default withRouter(LoadChiTactic);
