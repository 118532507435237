import Q from 'q';
import _ from 'lodash';
import Bluebird from 'bluebird';
// import Storage from 'dom-storage';
import EventEmitter from 'eventemitter2';
import request from 'request-promise-native';

import { IoC } from 'internal-ioc';
import jQuery from "jquery";
import camelCase from './utils/camel-case';

import modDev from 'device-api';
import helpers from 'device-api/helpers';
// import httpMod from 'device-api/request-http';
import { getApiAddress } from 'device-api/get-api-address';

import messageHelpers from 'message-api/helpers';
import messageContent from 'message-api/content';
import messageService from 'message-api/message-service';
import messageProcessors from 'message-api/message-processors';

// import leaderboardHelpersMod from 'leaderboard-api/highscore';

import loginModule from 'login-api/login';
import loginLookup from 'login-api/lookup';
import loginHelpers from 'login-api/helpers';
import loginRegistrationChecks from 'login-api/registration-checks';

// import { MessageUrl } from './utils/messageUrl';

import loginService from './services/loginService';
import externalPackageUtilityService from './services/externalPackageUtilityService';
// import { readMessage } from './services/readMessage';
// import { redeemMessage } from './services/redeemMessage';
// import { generateTraffic } from './services/generateTraffic';
// import { pressMessageButtons } from './services/pressMessageButtons';
// import { triggerRandomUnlocks } from './services/triggerRandomUnlocks';
// import { queryHighscore } from './services/mesageProcessors/queryHighscore';
import getSchemeSettingsHelper from './services/getSchemeSettings';
import getExternalIdHelper from './services/getExternalId';
// import { logger, loggerSymbol } from './services/logger';

import gameDeviceHelper from './lib/game-device';
import gameRandomHelper from './lib/game-random';
import localeHelper from 'content-hosting-interface-helpers/game-language'
import chiIocMod from 'content-hosting-interface-helpers/chi-ioc'
import gameThrows from './lib/game-throws';
import threeRadicalUtils from './lib/threeRadicalUtils';

import commonParams from './services/commonParams'
import constants from './utils/constants';
import config from "./config"

import chiHelpers from 'chi-helpers/helpers'

let ioc

const registrationSetup = (ioc, schemeSettings) => {
    let registrationChecks = []
    ioc.define('3r/registrationChecks', registrationChecks)
    ioc.call([/3r\/login-api\/registration-checks\//], function(registrationCheckModules) {
        //todo: check why original codes are !Object.keys(...)
        Object.keys(registrationCheckModules).forEach(function(key) {
            if (typeof registrationCheckModules[key] == 'function') {
                registrationChecks.push(registrationCheckModules[key].bind(null, schemeSettings))
            }
        })
    })

    function passRegistration(_, Q, registrationChecks) {
        return Q.all(registrationChecks.map((c) => c())).then(function(results) {
            return _.every(results, Boolean)
        })
    }
    passRegistration._depends = {
        symbol: '3r/passRegistration',
        modules: ['_', 'Q', '3r/registrationChecks']
    }

    return ioc.inject(passRegistration)
}

const getNewInjectionInstance = async (serverUrl, schemeId) => {
    window.apiAddress = config.serverUrl || window.apiAddress || window.location.origin
    
    const iocModules = {}
    ioc = new IoC(iocModules, global)
    
    ioc.define(constants.__symbolPrefix + 'ioc', ioc)

    ioc.define("window", window)
    ioc.define('EventEmitter', EventEmitter)
    ioc.define('Promise', Bluebird)
    ioc.define('3r/cookieJar', request.jar())
    ioc.define('_', _)
    ioc.define('Q', Q)
    ioc.define('jQuery', jQuery)
    ioc.define(
        "3r/storage",
        {
            setItem : function (){},
            getItem : function (){},
            removeItem : function (){},
            clear : function (){}
        }
    )
    
    ioc.injectAll(helpers)
    
    if (!schemeId) {
        schemeId = ioc.run("3r/getParameterByName", "schemeId")
    }
    // ioc.injectAll(httpMod)
    ioc.inject(modDev.http)
    // ioc.define('3r/getApiAddress', getApiAddress)
    ioc.inject(getApiAddress)

    const deviceFactory = ioc.inject(modDev.deviceFactory)
    ioc.injectAll(getSchemeSettingsHelper)
    ioc.injectAll(getExternalIdHelper)
    ioc.injectAll(messageHelpers)
    ioc.injectAll(messageContent)
    ioc.injectAll(messageService)

    ioc.injectAll(loginHelpers)
    ioc.injectAll(loginLookup)
    ioc.injectAll(loginModule)
    ioc.injectAll(loginRegistrationChecks)
    ioc.injectAll(chiIocMod)
    ioc.injectAll(messageProcessors)

    ioc.inject(loginService.login)
    ioc.injectAll(externalPackageUtilityService)

    ioc.injectAll(gameDeviceHelper)
    ioc.injectAll(gameRandomHelper)
    // ioc.define('3r/gameThrows', gameThrows)
    ioc.injectAll(gameThrows)
    ioc.injectAll(localeHelper)
    ioc.define('3r/threeRadicalUtils', threeRadicalUtils)

    ioc.injectAll(commonParams)

    const schemesettings = await ioc.run(getSchemeSettingsHelper.getSchemeSettingsSymbol, schemeId)
    ioc.define('3r/schemeSettings/webapp', schemesettings)

    appendTo(document.head, schemesettings.fonts || '')
    //Set up custom HTML fragments

    var additionalHtml = schemesettings.html
    if(additionalHtml && additionalHtml.head){
        var headFragments = additionalHtml.head
        Object.keys(headFragments).forEach(key => {
            appendTo(document.head, headFragments[key] || '')
        });
    }

    const externalId = await ioc.run(getExternalIdHelper.getExternalIdSymbol)
    ioc.define('3r/externalId', externalId)

    const Device = deviceFactory({ schemeId, paused : true })
    ioc.define('3r/Device', Device)
    ioc.define("3r/chi/helpers", chiHelpers)
    ioc.define("3r/chi/getPackageName", chiHelpers.getPackageName)
    ioc.define("3r/chi/camelCase", chiHelpers.camelCase)
    ioc.define("3r/chi/getThreeRadical", chiHelpers.getThreeRadical)
    if (typeof window == "object") {
        window.Device = Device
    }

    await ioc.call(
        [
            '3r/message-api/message-processors/MessageProcessors',
            '3r/message-api/message-service/MessageService',
            // /^3r\/message-api\/helpers\//,
            '3r/Device',
            // /^3r\/messageProcessorFactories\//
        ],
        async function(
            MessageProcessor,
            MessageService,
            // messageHelpers,
            Device,
            // messageProcessorFactories
        ) {
            // ioc.define('3r/mapToType', messageHelpers.initMapToType())

            ioc.define('3r/makeLanguage', function() {
                return function(english) {
                    return english
                }
            })

            let messageProcessor = new MessageProcessor()
            ioc.define('3r/messageProcessor', messageProcessor)
            ioc.define('3r/messageService', new MessageService(undefined, messageProcessor))

            // let messageUrls = ioc.call(['3r/mapToType'], function(mapToType) {
            //     return new MessageUrl(Device, mapToType)
            // })
            // ioc.define('3r/messageUrlResolver', messageUrls)

            // await Promise.all(
            //     Object.keys(messageProcessorFactories).map(function(factoryName) {
            //         return Promise.resolve(messageProcessorFactories[factoryName]())
            //     })
            // )

            registrationSetup(ioc, schemesettings)
        }
    )
    
    let schemePackages = _.get(schemesettings, "globals.schemePackages", [])
    
    let schemePackageModules = await Promise.all(schemePackages
        .filter(pkg => pkg['_package'] && pkg['_package'].main)
        .map(function (pkg) {
            let packageVarName = camelCase(pkg._package.name)
            let packageId = pkg.file.split("/").pop().replace(/\.zip$/,"")

            if (window[packageVarName]) {
                return Promise.resolve(Object.assign({ varName : packageVarName, module : window[packageVarName], id : packageId }, pkg))
            }

            return new Promise(function (accept, reject) {
                let mainJsAddress = Device.getApiAddress({
                    port: 3001,
                    path: "/external-package/" + schemeId + "/" + packageId + "/main.js"
                })

                var script = document.createElement('script')
                script.onload = function() {
                    accept(Object.assign({ varName : packageVarName, module : window[packageVarName], id : packageId }, pkg))
                }
                script.onerror = function (e) {
                    console.warn(e, e.stack)
                    reject(e)
                }

                script.src = mainJsAddress
                document.getElementsByTagName('body')[0].appendChild(script)
            })
        })
    )

    await Promise.all(schemePackageModules
        .filter(spm => spm.module && spm.module.init)
        .map(function (spm) {
            return Promise.resolve(spm.module.init({ ioc, Device }))
        })
    )

    return ioc
}

function appendTo(el, html){
    let tmpEl = document.createElement("div")
    tmpEl.innerHTML = html
    let node = tmpEl.firstChild
    while(tmpEl.firstChild){
        el.appendChild(tmpEl.firstChild)
    }
}

const getIoc = () => {
    return ioc
}


// module.exports = { getNewInjectionInstance }

const ModuleInjections = {
    registrationSetup: registrationSetup,
    getNewInjectionInstance: getNewInjectionInstance,
    getIoc: getIoc
}
export default ModuleInjections;
