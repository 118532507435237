import constants from '../utils/constants';
const updateParamSymbol = constants.__symbolPrefix + 'updateParam';
const getParamSymbol = constants.__symbolPrefix + 'getParam';
const removeParamSymbol = constants.__symbolPrefix + 'removeParamSymbol';

let params = {}

const updateParam = (key, value) => {
    params[key] = value
    return true
}
updateParam._depends = {
    symbol: updateParamSymbol,
    modules: []
}

const getParam = (key) => {
    return params[key]
}
getParam._depends = {
    symbol: getParamSymbol,
    modules: []
}

const removeParam = (key) => {
    delete params[key]
    return true
}
removeParam._depends = {
    symbol: removeParamSymbol,
    modules: []
}

const commonParams = { 
    updateParamSymbol,
    updateParam, 
    getParamSymbol,
    getParam,
    removeParamSymbol,
    removeParam
}
export default commonParams;
