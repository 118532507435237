import React, { Component } from 'react'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import { Link as RouterLink, Redirect as RouterRedirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import {UserContext} from "../UserContext"
import { withRouter } from "react-router-dom";

class Index extends Component {
    static contextType = UserContext

    render() {

        return (
            <Container maxWidth="sm">
                <Box my={4}>
                    <Typography variant="h1" component="h1">
                        Home
                    </Typography>
                    {/* <Link component={RouterLink} to="/Login">Login</Link>
                    <br></br>
                    <Link component={RouterLink} to="/Upload">Upload</Link> */}
                </Box>
            </Container>
        )
    }
}

export default withRouter(Index);