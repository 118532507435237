import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Container from '@material-ui/core/Container'
import { UserContext } from "../UserContext";
import getRandom from "../lib/game-random"
import getSchemeSettingsHelper from "../services/getSchemeSettings"
import Lottie from 'react-lottie';
import loadingService from "../services/loading"

class WinPrizeDetail extends Component {
    static contextType = UserContext
    constructor(props){
        super(props);
        console.log("WinPrizeDetail - props=", props)
        this.state = {
            realiseResult: null,
            settings: null
        };
        this.ioc = null
        this.gameRandom = null
        this.stateData = null
    }

    async componentDidMount(){
        this.stateData = this.props.location.state
        if(this.stateData && this.stateData.ioc){
            try{
                this.ioc = this.stateData.ioc
                let schemeId = this.ioc.run("3r/getParameterByName", "schemeId")
                let settings = this.ioc.run(getSchemeSettingsHelper.getCachedSchemeSettingsSymbol)
                this.setState({
                    settings: settings
                })
                this.getRandom = this.ioc.run(getRandom.gameRandomSymbol, schemeId, this.stateData.currentMessage, (msg) => {
                    // TODO: Implement function to show an alert message. The alert with snackbar is based around LoadChiTactic component.
                    // But LoadChiTactic is no longer mounted when we mount this component. So, need to setup another alert system.
                    console.log('Alert!!!', msg)
                })
                
                let redirectData = this.stateData.redirectData
                let isMultipleWin = this.stateData.isMultipleWin
                console.log("isMultipleWin=" + isMultipleWin + ", redirectData=", redirectData)
                
                //Show ErrorPage when timeout, because 
                
                let realiseResult
                if(isMultipleWin){
                    realiseResult = await this.getRandom.realise(redirectData.currentMessage.systemId, {unlockCode: redirectData.prize.unlock})
                }else{
                    realiseResult = await this.getRandom.realise(redirectData.currentMessage.systemId)
                }
                console.log("00 mark: this.state.realiseResult=", this.state.realiseResult)
                if(realiseResult.error){
                    let error
                    if(realiseResult.errorMessage && typeof realiseResult.errorMessage == "string"){
                        try{
                            error = JSON.parse(realiseResult.errorMessage)
                        }catch(e){
                            error = realiseResult
                        }
                    }else{
                        error = realiseResult
                    }

                    this.goErrorPage({
                        title: error.title,
                        errorCode: error.code,
                        errorMsg: error.description || "No error description is provided"
                    })
                    return
                }

                if(this.ioc.isDefined("3r/chi/tacticReplayOptions")) {
                    let replayOptions = (await Promise.resolve(this.ioc.run("3r/chi/tacticReplayOptions"))) || {}
                    console.log("00 mark: replayOptions=", replayOptions)
                    if(replayOptions.error) {
                        return this.goErrorPage(replayOptions)
                    }else{
                        realiseResult.chanceToPlay = replayOptions.chanceToPlay
                    }
                }

                this.setState({
                    realiseResult: realiseResult
                })
            }catch(e){
                console.error("WinPrizeDetail error: ", e)
                this.goErrorPage({
                    title: "Service not available",
                    errorMsg: "This service is currently unavailable, please try again later."
                })
            }
        }
    }

    goErrorPage = (error) => {
        this.props.history.push({
            pathname: '/ErrorPage',
            search: '',
            state: {
                redirectData: error
            }
        })
    }

    evaluateInContext(messageTemplate, wonPrizeText){
        let self = this
        //sample messageTemplate: "Congratulations! \nYou have won {{text}}!"
        return messageTemplate.replace(/\{\{([^\}]+)\}\}/ig, function (match, capture) {
            if(capture == "text"){
                return wonPrizeText
            }
            // else if(capture == "points"){
            //     return self.fortuneCookies.points
            // }
        })
    }

    replayGame(){
        this.props.history.push({
            pathname: '/LoadChiTactic',
            search: '',
            state: {
                ioc: this.ioc,
                isReplayGame: true
            }
        })
    }

    renderPrize(settings, prize){
        if(settings.isVoucherAminated){
            return (
                <div>
                    <Lottie 
                        options={settings.styles.voucherLottieOptions}
                        width={"70%"}
                        height={"100%"}
                    />
                    { settings.hyperlink && settings.hyperlink.systemIds.includes(this.stateData.redirectData.currentMessage.systemId.toString()) 
                        ? <a style={settings.hyperlink.bottomStyle} href={settings.hyperlink.url || ""} target={settings.hyperlink.target || "_self"}>{settings.hyperlink.buttonText}</a> 
                        : ""
                    }
                </div>
            )
        }else{
            return (
                <img src={prize.image || ""} style={(settings.styles || {}).prizeImageStyle}></img>
            )
        }
    }

    renderBottomBar(styles){
        let gameChance = this.state.realiseResult.chanceToPlay || 0;
        let gameChanceText = "You have " + gameChance + " more game chance" + (gameChance > 1 ? "s" : "")
        if(gameChance > 0){
            let replayText = "PLAY AGAIN"
            return (
                <div style={styles.bottomBarStyle}>
                    <div style={styles.gameChanceTextStyle}>{gameChanceText}</div>
                    <div style={styles.replayBtnStyle} onClick={() => {this.replayGame()}}>{replayText}</div>
                </div>
            )
        }else{
            return (
                <div style={styles.bottomBarStyle}>
                    <div style={styles.gameChanceTextStyle}>{gameChanceText}</div>
                </div>
            )
        }
    }

    render() {
        let realiseResult = this.state.realiseResult
        if(!realiseResult){
            let loadingStyles = loadingService.getStyles()
            let msg = "Processing..."
            return (
                <Container maxWidth="sm" disableGutters={true} style={loadingStyles.containerStyle}>
                    {loadingService.getLoadingText(msg)}
                    {loadingService.renderImage()}
                </Container>
            )
        }else{
            let settings = this.state.settings
            if(settings){
                let winPrizeSetting = (settings.globals.sta || {}).winPrize || {}
                let styles = winPrizeSetting.styles || {}

                let prize = this.stateData.redirectData.prize || {}
                let congratsMsg = this.evaluateInContext(this.stateData.redirectData.winMessageTemplate || "Congratulations! \nYou have won {{text}}!", prize.description || "")
                
                return (
                    <Container maxWidth="sm" disableGutters={true}>
                        {
                            styles.confettiLottieOptions ? 
                                <Lottie 
                                    options={styles.confettiLottieOptions}
                                    width={"100%"}
                                    height={"100%"}
                                    style={styles.confettiStyle}
                                />
                            : 
                            ""
                        }
                        <div style={styles.congratsContainerStyle}>
                            <div style={styles.congratsMsgStyle}>{congratsMsg}</div>
                            <div style={styles.prizeImageContainerStyle}>
                                {this.renderPrize(winPrizeSetting, prize)}
                            </div>
                            {this.renderBottomBar(styles)}
                        </div>
                    </Container>
                )
            }else{
                return null
            }
        }
    }
}

export default withRouter(WinPrizeDetail);
