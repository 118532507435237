import constants from '../utils/constants';
import { default as  gameRandomHelperAlias } from 'chi-helpers/game-random'

const gameRandomSymbol = constants.__symbolPrefix + 'gameRandom'

const gameRandom = gameRandomHelperAlias.gameRandom

gameRandom._depends = {
    symbol: gameRandomSymbol,
    modules: [
        "3r/Device",
        constants.__symbolPrefix + 'updateParam',
	"3r/chi/helpers",
	"_"
    ]
}

const gameRandomHelper = {
    gameRandomSymbol,
    gameRandom
}
export default gameRandomHelper;
