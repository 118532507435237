import React from 'react';

let loading
let styles
let loadingImage
let hideStyle = {
    display: "none"
}

const initLoading = (settings) => {
    if(settings){
        loading = (settings.globals.sta || {}).loading || {}
        styles = loading.styles || {}
        loadingImage = (loading.images || {}).loadingImage

        if(styles.containerStyle && styles.containerStyle.backgroundImageUrl){
            styles.containerStyle.backgroundImage = `url(${styles.containerStyle.backgroundImageUrl})`
        }
        return styles
    }else{
        return {}
    }
}

const showLoadingBeforeGameLoaded = () => {
    return loading && loading.showLoadingBeforeGameLoaded
}

const getStyles = () => {
    return styles || {}
}

const getHideStyle = () => {
    return hideStyle
}

const getLoadingText = (msg) => {
    if(styles){
        return (
            <div style={styles.msgStyle}>{msg}</div>
        )
    }else{
        return null
    }
}

const renderImage = () => {
    if(styles && loadingImage){
        return (
            <div style={styles.imageContainerStyle}>
                <img src={loadingImage} style={styles.imageStyle} alt=""></img>
            </div>
        )
    }else{
        return null
    }
}

const loadingService = {
    initLoading: initLoading,
    showLoadingBeforeGameLoaded: showLoadingBeforeGameLoaded,
    getStyles: getStyles,
    getHideStyle: getHideStyle,
    getLoadingText: getLoadingText,
    renderImage: renderImage
}
export default loadingService;
